import axios from 'axios';

const SubmissionService = {
  addOrganisation(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Organisation/AddOrganisation`;
    return axios.post(url, json);
  },

  updateOrganisation(ID, json) {
    const url = `${process.env.REACT_APP_HOST_URL}Organisation/${ID}`;
    return axios.put(url, json);
  },

  deleteOrganisation(Id) {
    const url = `${process.env.REACT_APP_HOST_URL}organisation/${Id}`;
    return axios.delete(url);
  },

  getSubmissions(parameters) {
    const url = `${process.env.REACT_APP_HOST_URL}Submission/GetSubmissions${parameters}`;
    return axios.get(url);
  },

  getOrganisation(ID) {
    const url = `${process.env.REACT_APP_HOST_URL}Organisation/${ID}`;
    return axios.get(url);
  },

  getScheduleDetails(scheduleId) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetGeneratedScheduleDetails/${scheduleId}`;
    return axios.get(url);
  },

  getSubmissionDetails(submissionId) {
    const url = `${process.env.REACT_APP_HOST_URL}Submission/GetGeneratedScoreSubmissions/${submissionId}`;
    return axios.get(url);
  },

  exportSubmissions(parameters) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/ExportSchedule${parameters}`;
    return axios.get(url, { responseType: 'arraybuffer' });
  },

  exportStatistics(parameters) {
    const url = `${process.env.REACT_APP_HOST_URL}Submission/ExportStatistics${parameters}`;
    return axios.get(url, { responseType: 'arraybuffer' });
  },
  getCalendarDetails(scheduleId, channeId, month, year) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetCalendarDetails/${scheduleId}?ChannelId=${channeId}&Month=${month}&Year=${year}`;
    return axios.get(url);
  },
};

export default SubmissionService;
