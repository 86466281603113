import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './sidebar.module.css';
import usersIcon from '../../assets/icons/Users.png';
import op2miseLogo from '../../assets/icons/op2mise.svg';
import LogoCollapsed from '../../assets/icons/op2miseLogoSmall.svg';
import settingsIcon from '../../assets/icons/Settings-icon.svg';
import planningIcon from '../../assets/icons/Planning.svg';
import channelIcon from '../../assets/icons/Channel-icon.svg';
import priotisationIcon from '../../assets/icons/Prioritisation.svg';
import scheduleIcon from '../../assets/icons/Schedule-icon.svg';
import titleGroupIcon from '../../assets/icons/TitleGroups.svg';
import submissionIcon from '../../assets/icons/Submission-icon.svg';
import helpIcon from '../../assets/icons/Help-icon.svg';
import organisationIcon from '../../assets/icons/Organisation-icon.svg';
import channelSidebarIcon from '../../assets/icons/channel-sidebar.svg';
import channelGroupSidebarIcon from '../../assets/icons/channelgroup-sidebar.svg';
import titleExclusionIcon from '../../assets/icons/Title-Exclusions.svg';
import attributesOverrideIcon from '../../assets/icons/Attributes-Overrides.svg';
import emailTriggerIcon from '../../assets/icons/Email-Trigger.svg';
import chevRightIcon from '../../assets/icons/Chevron-Right.png';

import ProfileComponent from '../profile/profile.component';
import ProfileService from '../profile/profile.service';
import AuthService from '../../core/auth.service';

const { forwardRef, useImperativeHandle } = React;
const SidebarComponent = forwardRef(
  (
    {
      toggleSidebar,
      callbackUserLogout,
      isProfileUpdated,
      setIsProfileUpdated,
    },
    ref
  ) => {
    const profileComponentRef = useRef();
    useImperativeHandle(ref, () => ({
      updateSidebarProfile(id) {
        profileComponentRef.current.updateSidebarProfile(id);
      },
    }));
    const location = useLocation();
    const [sidebarState, setSidebarState] = useState(false);
    const [settingsLinks, setSettingsLinks] = useState(false);
    const [planningLinks, setPlanningLinks] = useState(false);
    const [profileLinkState, setProfileLinkState] = useState(false);

    const [role, setRole] = useState();
    const [logo, setLogo] = useState();
    const [collapsedLogo, setCollapsedLogo] = useState(LogoCollapsed);

    const getUser = () => {
      ProfileService.getUser(AuthService.getUserIdentity().userId).then(
        (response) => {
          setRole(response.data.role);
          if (
            response.data.organisationPic !== null &&
            response.data.organisationPic !== ''
          ) {
            setLogo(response.data.organisationPic);
            setCollapsedLogo(response.data.organisationPic);
          } else {
            setLogo(op2miseLogo);
            setCollapsedLogo(LogoCollapsed);
          }
        }
      );
    };

    const toggleSidebarState = (state) => {
      setSidebarState(state);
      toggleSidebar(state);
    };

    const showSettingsLinks = () => {
      setSettingsLinks(!settingsLinks);
    };

    const showPlanningLinks = () => {
      setPlanningLinks(!planningLinks);
    };

    const hideProfileLink = (state) => {
      setProfileLinkState(state);
    };

    const getBrowserWidth = () =>
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );

    useEffect(() => {
      getUser();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const handleResize = () => {
        if (getBrowserWidth() < 800) {
          toggleSidebarState(true);
        } else {
          toggleSidebarState(false);
        }
      };

      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
      if (getBrowserWidth() < 800) {
        toggleSidebarState(true);
      } else {
        toggleSidebarState(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="position-relative vh-100">
        <div className={styles.sidebarHeader}>
          {logo && (
            <img
              className={sidebarState ? styles.vaiLogoSmall : styles.vaiLogo}
              src={sidebarState ? collapsedLogo : logo}
              alt="op2miseLogo"
            />
          )}
        </div>
        <div
          id="scrollbarStyle"
          className={` 
          // sidebarState ? styles.sidebarStateListDiv : styles.sideBarListDiv,
          ${
            !profileLinkState
              ? styles.sidebarStateListDiv
              : styles.sideBarListDiv
          }  scrollbarStyle`}
        >
          <div className={styles.sidebarList}>
            {(role === 'Organisation User' ||
              role === 'Organisation Admin') && (
              <>
                <NavLink
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  title={sidebarState ? 'Channel Setup' : null}
                  to={`${process.env.REACT_APP_SUBFOLDER}/channel-profiles`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={channelIcon}
                    alt="channelIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Channel Setup
                  </span>
                </NavLink>

                <div
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  title={sidebarState ? 'Planning' : null}
                  style={{ cursor: 'pointer' }}
                  onClick={showPlanningLinks}
                  onKeyDown={showPlanningLinks}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    className={sidebarState ? styles.vaiCenterText : null}
                    src={planningIcon}
                    alt="PlanningIcon"
                    style={{ marginLeft: '10px' }}
                  />
                  <span
                    className={`${sidebarState ? styles.hideText : null} mx-3`}
                  >
                    Planning
                    <img
                      className={`${styles.vaiChevRight} ${
                        planningLinks ? styles.vaiChevRightRotate : null
                      }`}
                      src={chevRightIcon}
                      title={planningLinks ? 'Show less' : 'Show more'}
                    />
                  </span>
                </div>
                <div
                  style={{
                    display: planningLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '15px',
                  }}
                >
                  {(role === 'Organisation User' ||
                    role === 'Organisation Admin') && (
                    <>
                      <NavLink
                        title={sidebarState ? 'Title Groups' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/title-groups`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={titleGroupIcon}
                          alt="titlegroups"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Title Groups
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Prioritisation' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/prioritisation`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                            marginRight: '5px',
                          }}
                          src={priotisationIcon}
                          alt="priotisationIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Prioritisation
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Title Exclusions' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/title-exclusions`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={titleExclusionIcon}
                          alt="titleexclusionss"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Title Exclusions
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Attributes Override' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/attributes-override`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={attributesOverrideIcon}
                          alt="attributesOverride"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Attributes Override
                        </span>
                      </NavLink>
                    </>
                  )}
                </div>

                <NavLink
                  title={sidebarState ? 'Schedules' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative  ${
                      location.pathname ===
                      `${process.env.REACT_APP_SUBFOLDER}/scheduler`
                        ? styles.activeLink
                        : ''
                    }`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/schedule`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={scheduleIcon}
                    alt="scheduleIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Schedules
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Submissions' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative  ${
                      location.pathname ===
                      `${process.env.REACT_APP_SUBFOLDER}/submission-scheduler`
                        ? styles.activeLink
                        : ''
                    }`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/submission`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={submissionIcon}
                    alt="submissionIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Submissions
                  </span>
                </NavLink>
              </>
            )}
            {role === 'Organisation Admin' && (
              <>
                <div
                  title={sidebarState ? 'Settings' : null}
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  style={{ cursor: 'pointer' }}
                  onClick={showSettingsLinks}
                  onKeyDown={showSettingsLinks}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    className={sidebarState ? styles.vaiCenterText : null}
                    src={settingsIcon}
                    alt="SettingsIcon"
                    style={{ marginLeft: '10px' }}
                  />
                  <span
                    className={` ${sidebarState ? styles.hideText : null} mx-3`}
                  >
                    Settings
                    <img
                      className={`${styles.vaiChevRight} ${
                        settingsLinks ? styles.vaiChevRightRotate : null
                      }`}
                      src={chevRightIcon}
                      title={settingsLinks ? 'Show less' : 'Show more'}
                    />
                  </span>
                </div>
                <div
                  style={{
                    display: settingsLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '12px',
                  }}
                >
                  <NavLink
                    title={sidebarState ? 'Users' : null}
                    className={(navData) =>
                      `${
                        navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative`
                    }
                    to={`${process.env.REACT_APP_SUBFOLDER}/users`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{
                        marginLeft: sidebarState ? '15px' : '10px',
                        marginBottom: '5px',
                      }}
                      src={usersIcon}
                      alt="UsersIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      Users
                    </span>
                  </NavLink>

                  {role === 'Organisation Admin' && (
                    <>
                      <NavLink
                        title={sidebarState ? 'Channel Groups' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/channel-groups`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={channelGroupSidebarIcon}
                          alt="channelGroupIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Channel Groups
                        </span>
                      </NavLink>

                      <NavLink
                        title={sidebarState ? 'Channels' : null}
                        className={(navData) =>
                          `${
                            navData.isActive ? styles.activeLink : styles.link
                          } nav-item nav-link px-0 py-2 position-relative`
                        }
                        to={`${process.env.REACT_APP_SUBFOLDER}/channels`}
                      >
                        <img
                          className={sidebarState && styles.centerText}
                          style={{
                            marginLeft: sidebarState ? '15px' : '10px',
                            marginBottom: '5px',
                          }}
                          src={channelSidebarIcon}
                          alt="channelIcon"
                        />
                        <span
                          className={` ${sidebarState && styles.hideText} mx-3`}
                        >
                          Channels
                        </span>
                      </NavLink>
                    </>
                  )}
                </div>
              </>
            )}
            {role === 'Super Admin' && (
              <>
                <NavLink
                  title={sidebarState ? 'Organisations' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/organisation`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={organisationIcon}
                    alt="organisationIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Organisations
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Users' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/users`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={usersIcon}
                    alt="usersIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Users
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Channel Groups' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/channel-groups`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '5px', marginBottom: '5px' }}
                    src={channelGroupSidebarIcon}
                    alt="channelGroupIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Channel Groups
                  </span>
                </NavLink>

                <NavLink
                  title={sidebarState ? 'Channels' : null}
                  className={(navData) =>
                    `${
                      navData.isActive ? styles.activeLink : styles.link
                    } nav-item nav-link px-0 py-2 position-relative`
                  }
                  to={`${process.env.REACT_APP_SUBFOLDER}/channels`}
                >
                  <img
                    className={sidebarState && styles.centerText}
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                    src={channelSidebarIcon}
                    alt="channelIcon"
                  />
                  <span className={` ${sidebarState && styles.hideText} mx-3`}>
                    Channels
                  </span>
                </NavLink>

                <div
                  title={sidebarState ? 'Settings' : null}
                  className={`${styles.vaiLink} nav-item nav-link px-0 py-2 position-relative`}
                  style={{ cursor: 'pointer' }}
                  onClick={showSettingsLinks}
                  onKeyDown={showSettingsLinks}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    className={sidebarState ? styles.vaiCenterText : null}
                    src={settingsIcon}
                    alt="SettingsIcon"
                    style={{ marginLeft: '10px' }}
                  />
                  <span
                    className={`${sidebarState ? styles.hideText : null} mx-3`}
                  >
                    Settings
                    <img
                      className={`${styles.vaiChevRight} ${
                        settingsLinks ? styles.vaiChevRightRotate : null
                      }`}
                      src={chevRightIcon}
                      title={settingsLinks ? 'Show less' : 'Show more'}
                    />
                  </span>
                </div>
                <div
                  style={{
                    display: settingsLinks ? null : 'none',
                    marginLeft: sidebarState ? '2px' : '12px',
                  }}
                >
                  <NavLink
                    title={sidebarState ? 'Email Triggers' : null}
                    className={(navData) =>
                      `${
                        navData.isActive ? styles.activeLink : styles.link
                      } nav-item nav-link px-0 py-2 position-relative`
                    }
                    to={`${process.env.REACT_APP_SUBFOLDER}/email-triggers`}
                  >
                    <img
                      className={sidebarState && styles.centerText}
                      style={{
                        marginLeft: sidebarState ? '15px' : '10px',
                        marginBottom: '5px',
                      }}
                      src={emailTriggerIcon}
                      alt="emailTriggerIcon"
                    />
                    <span
                      className={` ${sidebarState && styles.hideText} mx-3`}
                    >
                      Email Triggers
                    </span>
                  </NavLink>
                </div>
              </>
            )}

            <div className={styles.help}>
              <a
                title={sidebarState ? 'Help' : null}
                className={(navData) =>
                  `${
                    navData.isActive ? styles.activeLink : styles.link
                  } nav-item nav-link px-0 py-2 position-relative`
                }
                href="https://help.op2mise.tv/docs/"
                target="_blank"
              >
                <img
                  className={sidebarState && styles.centerText}
                  style={{ marginLeft: '10px', marginBottom: '5px' }}
                  src={helpIcon}
                  alt="helpIcon"
                />
                <span className={` ${sidebarState && styles.hideText} mx-3`}>
                  Help
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className={styles.profileSection}>
          <ProfileComponent
            ref={profileComponentRef}
            sidebarState={sidebarState}
            toggleSidebar={toggleSidebarState}
            hideProfileLink={(state) => hideProfileLink(state)}
            callbackUserLogout={callbackUserLogout}
            isProfileUpdated={isProfileUpdated}
            setIsProfileUpdated={setIsProfileUpdated}
          />
        </div>
      </div>
    );
  }
);
SidebarComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  callbackUserLogout: PropTypes.func.isRequired,
};
SidebarComponent.displayName = 'SidebarComponent';
export default SidebarComponent;
