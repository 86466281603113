import React from 'react';
import PropTypes from 'prop-types';

import NotificationComponent from './NotificationComponent';

export default function NotificationManager({ setNotify }) {
  const [notifications, setNotifications] = React.useState([]);
  let newid = 0;
  const createNotification = ({ color, autoClose, children }) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      {
        children,
        color,
        autoClose,
        id: (newid += 1),
      },
    ]);
  };

  React.useEffect(() => {
    // prettier-ignore
    setNotify(({ color, autoClose, children }) => (
      createNotification({ color, autoClose, children })
    ));
  }, [setNotify]);

  const deleteNotification = (id) => {
    const filteredNotifications = notifications.filter(
      (_, index) => id !== index,
      []
    );
    setNotifications(filteredNotifications);
  };

  return notifications.map(({ id, ...props }, index) => (
    <NotificationComponent
      key={id}
      onDelete={() => deleteNotification(index)}
      // eslint-disable-next-line
      {...props}
    />
  ));
}

NotificationManager.propTypes = {
  setNotify: PropTypes.func.isRequired,
};
