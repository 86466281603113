import React from 'react';
import PropTypes from 'prop-types';
import Styles from './GeneratedScheduleBanner.module.css';

function GeneratedScheduleBannerComponent({ componentName, scheduleDetails }) {
  return (
    <div className={`${Styles.header} position-relative`}>
      <b className={Styles.headerText}>{componentName}</b>
      <div className="d-flex position-absolute end-0">
        <div className={`row ${Styles.marginBottom}`}>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Channel:</div>
          </div>
          <div className={Styles.divHeaderPadding}>
            <strong className={Styles.channelLabel}>
              {scheduleDetails.channelName}
            </strong>
          </div>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Period:</div>
          </div>
          <div className={Styles.divHeaderPadding}>
            <strong className={Styles.channelLabel}>
              {scheduleDetails.period}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}
GeneratedScheduleBannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  scheduleDetails: PropTypes.shape(),
};
export default GeneratedScheduleBannerComponent;
