const RouterService = {
    isValidUrl(url) {
        const validUrls = [
            '/',
            '/organisation',
            '/email-triggers',
            '/channel-profiles',
            '/title-groups',
            '/prioritisation',
            '/title-exclusions',
            '/attributes-override',
            '/schedule',
            '/scheduler',
            '/scheduledetails',
            '/submission',
            '/submission-scheduler',
            '/users',
            '/channel-groups',
            '/channels',
            '/access-denied',
            '/not-found',
            '/request-timed-out',
            '/maintenance',
            '/profile',
        ];

        return validUrls.includes(url) || url.includes('/internal-server');
    },

    isAccessibleForSuperAdmin(url) {
        const accessiblePages = [
            '/',
            '/users',
            '/organisation',
            '/channel-groups',
            '/channels',
            '/email-triggers',
            '/access-denied',
            '/not-found',
            '/request-timed-out',
            '/maintenance',
            '/profile',
        ];
        return accessiblePages.includes(url) || url.includes('/internal-server');
      },
    
      isAccessibleForOrganisationalAdmin(url) {
        const accessiblePages = [
            '/',
            '/channel-profiles',
            '/title-groups',
            '/prioritisation',
            '/title-exclusions',
            '/attributes-override',
            '/schedule',
            '/scheduler',
            '/scheduledetails',
            '/submission',
            '/submission-scheduler',
            '/users',
            '/channel-groups',
            '/channels',
            '/access-denied',
            '/not-found',
            '/request-timed-out',
            '/maintenance',
            '/profile',
        ];
        return accessiblePages.includes(url) || url.includes('/internal-server');
      },
    
      isAccessibleForOrganisationalUser(url) {
        const accessiblePages = [
          '/',
          '/channel-profiles',
          '/title-groups',
          '/prioritisation',
          '/title-exclusions',
          '/attributes-override',
          '/schedule',
          '/scheduler',
          '/scheduledetails',
          '/submission',
          '/submission-scheduler',
          '/access-denied',
          '/not-found',
          '/request-timed-out',
          '/maintenance',
          '/profile',
        ];
        return accessiblePages.includes(url) || url.includes('/internal-server');
      },
};

export default RouterService;