import React from 'react';
import ReactDOM from 'react-dom';

import NotificationManager from './NotificationManagerComponent';
import NotificationComponent, { Color } from './NotificationComponent';
import createNotificationContainer from './NotificationContainer';

const containerElement = createNotificationContainer();
let notify;

ReactDOM.render(
  <NotificationManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />,
  containerElement,
);

export { NotificationComponent, Color };

export function info(children, autoClose = true) {
  return notify({
    color: Color.info,
    children,
    autoClose,
  });
}

export function success(children, autoClose = true) {
  return notify({
    color: Color.success,
    children,
    autoClose,
  });
}

export function warning(children, autoClose = true) {
  return notify({
    color: Color.warning,
    children,
    autoClose,
  });
}

export function danger(children, autoClose = true) {
  return notify({
    color: Color.error,
    children,
    autoClose,
  });
}
