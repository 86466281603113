import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

function Input({
  ref,
  label,
  name,
  type,
  placeHolder,
  value,
  showErrorMessage,
  errorMessage,
  maxLength,
  isRequired,
  width,
  onChange,
  onFocus,
  onBlur,
  disabled,
  pattern
}) {
  return (
    <>
      {label && (
        <div className={styles.label}>
          {label}
          {' '}
          {isRequired && <span style={{ color: '#FF0000' }}>*</span>}
        </div>
      )}

      <input
        ref={ref}
        className={`${styles.input} ${showErrorMessage && styles.errorInput}`}
        style={{ width }}
        name={name && name}
        type={type && type}
        placeholder={placeHolder && placeHolder}
        value={value && value}
        maxLength={maxLength && maxLength}
        autoComplete="off"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        pattern={pattern && pattern}
      />

      {showErrorMessage && (
        <small className={styles.errorMessage}>{errorMessage}</small>
      )}
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  label: null,
  name: null,
  type: 'text',
  placeHolder: null,
  value: null,
  showErrorMessage: false,
  errorMessage: null,
  maxLength: null,
  isRequired: false,
  width: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
};

export default Input;
