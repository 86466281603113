import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './profile.module.css';
import chevIcon from '../../assets/icons/Chevron-Right.png';
import defaultAvatar from '../../assets/icons/DefaultAvatar.png';
import hamburgerMenuIcon from '../../assets/icons/Hamburger-Menu.png';
import hamburgerMenuToggledIcon from '../../assets/icons/Hamburger-Menu-Toggled.png';
import horizontalEllipsisIcon from '../../assets/icons/Horizontal-Ellipsis.png';
import signOutIcon from '../../assets/icons/Sign-Out.png';
import profileIcon from '../../assets/icons/Profile-icon.svg';

import authService from '../../core/auth.service';
import ProfileService from './profile.service';
import AuthService from '../../core/auth.service';
import { data } from 'jquery';

const { forwardRef, useImperativeHandle } = React;
const ProfileComponent = forwardRef(
  (
    {
      toggleSidebar,
      hideProfileLink,
      callbackUserLogout,
      isProfileUpdated,
      setIsProfileUpdated,
    },
    ref
  ) => {
    const nameRef = useRef(null);
    const [sidebarState, setSidebarState] = useState(false);
    const [userIdentity, setUserIdentity] = useState({
      firstName: '',
      lastName: '',
      jobTitle: '',
      profilePic: defaultAvatar,
    });
    const [hideProfileLinkState, setHideProfileLinkState] = useState(true);
    const [profileTitle, setProfileTitle] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);

    const getUserInfo = () => {
      ProfileService.getUser(authService.getUserIdentity().userId).then(
        (response) => {
          setIsProfileUpdated(false);
          setUserIdentity({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            jobTitle: response.data.jobTitle,
            profilePic:
              response.data.profilePic === '' ||
              response.data.profilePic === null
                ? null
                : response.data.profilePic,
          });
        }
      );
    };

    const getBrowserWidth = () =>
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );

    const toggleSidebarState = () => {
      setSidebarState(!sidebarState);
      toggleSidebar(!sidebarState);
    };

    const handleLogout = () => {
      authService.logout();
      callbackUserLogout();
    };

    const toggleSetHideProfileLink = () => {
      hideProfileLink(!hideProfileLinkState);
      setHideProfileLinkState(!hideProfileLinkState);
    };

    const getProfileTItle = () => {
      if (nameRef.current) {
        return nameRef.current.offsetWidth &&
          nameRef.current.offsetWidth != null &&
          nameRef.current.offsetWidth < nameRef.current.scrollWidth
          ? `${userIdentity.firstName} ${userIdentity.lastName}`
          : null;
      }
      return null;
    };

    const getJobTItle = () => {
      if (nameRef.current) {
        return nameRef.current.offsetWidth &&
          nameRef.current.offsetWidth != null &&
          nameRef.current.offsetWidth < nameRef.current.scrollWidth
          ? `${userIdentity.jobTitle}`
          : null;
      }
      return null;
    };

    useImperativeHandle(ref, () => ({
      updateSidebarProfile(id) {
        getUserInfo(id);
      },
    }));

    useEffect(() => {
      if (isProfileUpdated) {
        getUserInfo(AuthService.getUserIdentity().userId);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProfileUpdated]);

    useEffect(() => {
      const handleResize = () => {
        if (getBrowserWidth() < 800) {
          setSidebarState(true);
        } else {
          setSidebarState(false);
        }
      };

      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
      if (getBrowserWidth() < 800) {
        setSidebarState(true);
      } else {
        setSidebarState(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getUserInfo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setProfileTitle(getProfileTItle());
      setJobTitle(getJobTItle());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameRef, userIdentity]);

    return (
      <>
        <div className="row mb-4 pb-1">
          {/* <div className='row pb-1'> */}
          <div className="col-12" style={{ paddingRight: '10px' }}>
            {sidebarState ? (
              <img
                src={hamburgerMenuToggledIcon}
                alt="HamburgerMenuToggledIcon"
                className={`${styles.hamburgerMenuToggledIcon} mx-4`}
                onClick={toggleSidebarState}
                onKeyDown={toggleSidebarState}
                role="presentation"
                title="Expand menu"
              />
            ) : (
              <img
                src={hamburgerMenuIcon}
                alt="HamburgerMenuIcon"
                className="float-end"
                onClick={toggleSidebarState}
                style={{ cursor: 'pointer' }}
                onKeyDown={toggleSidebarState}
                role="presentation"
                title="Collapse menu"
              />
            )}
          </div>
        </div>
        <div className="mb-3 px-3 d-flex position-relative">
          <div className="position-relative">
            <img
              src={
                userIdentity.profilePic != null
                  ? userIdentity.profilePic
                  : defaultAvatar
              }
              className={`${styles.vaiProfilePhoto} ${
                sidebarState ? styles.vaiShrinkPhoto : null
              }`}
              alt="ProfilePhoto"
              style={{ marginLeft: sidebarState ? '2px' : null }}
            />
          </div>
          <div
            className={`${
              sidebarState ? styles.vaiHide : null
            } position-relative`}
            style={{
              overflowX: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              marginLeft: '15px',
              width: '100px',
            }}
          >
            <p
              ref={nameRef}
              className={styles.vaiUsername}
              style={{
                width: '100px',
              }}
            >
              <span title={profileTitle}>
                {`${userIdentity.firstName} ${userIdentity.lastName}`}
              </span>
              <br />{' '}
              <span className={styles.vaiUserTitle} title={jobTitle}>
                {userIdentity.jobTitle}
              </span>
            </p>
          </div>
          <span
            onClick={toggleSetHideProfileLink}
            style={{ cursor: 'pointer', paddingLeft: '28px' }}
            onKeyDown={toggleSetHideProfileLink}
            role="button"
            tabIndex={0}
            title={
              hideProfileLinkState ? 'Show options/sign out' : 'Hide options'
            }
          >
            <img
              src={chevIcon}
              alt="ChevUpIcon"
              className={`${styles.vaiChevUp} ${
                sidebarState ? styles.vaiHide : null
              }`}
            />
            <img
              src={chevIcon}
              alt="ChevDownIcon"
              className={`${styles.vaiChevDown} ${
                sidebarState ? styles.vaiHide : null
              }`}
            />
          </span>
        </div>
        <div
          className="text-center"
          style={{
            display: sidebarState ? null : 'none',
            marginTop: '-15px',
            marginRight: '28px',
          }}
          onClick={toggleSetHideProfileLink}
          onKeyDown={toggleSetHideProfileLink}
          role="button"
          tabIndex={0}
          title={
            hideProfileLinkState ? 'Show options/sign out' : 'Hide options'
          }
        >
          <img src={horizontalEllipsisIcon} alt="Horizontal-Ellipsis" />
        </div>

        <div
          className={`${sidebarState ? 'px-4' : 'px-3'} mx-1 position-relative`}
          style={{
            cursor: 'pointer',
            marginTop: hideProfileLinkState ? '10px' : '10px',
            display: hideProfileLinkState && 'none',
            marginBottom: hideProfileLinkState ? '-10px' : '25px',
          }}
          to={`${process.env.REACT_APP_SUBFOLDER}/profile`}
        >
          <NavLink
            title="Profile"
            className={(navData) =>
              `${
                navData.isActive ? styles.activeLink : styles.link
              } nav-item nav-link px-0 py-2 position-relative`
            }
            to={`${process.env.REACT_APP_SUBFOLDER}/profile`}
          >
            <img
              src={profileIcon}
              alt="profileIcon"
              style={{ marginLeft: '-3px' }}
            />
            <span
              className={styles.linkText}
              style={{ display: sidebarState ? 'none' : null }}
            >
              Profile
            </span>
          </NavLink>
        </div>

        <div
          title="Sign Out"
          className={`mx-1 position-relative`}
          style={{
            cursor: 'pointer',
            marginTop: hideProfileLinkState ? '10px' : '-10px',
            display: hideProfileLinkState && 'none',
            paddingLeft: sidebarState ? '21px' : '13px',
          }}
          onClick={handleLogout}
          onKeyDown={handleLogout}
          role="button"
          tabIndex={0}
        >
          <img src={signOutIcon} alt="Horizontal-Ellipsis" />
          <span
            className={styles.vaiSignOutText}
            style={{ display: sidebarState ? 'none' : null }}
          >
            Sign Out
          </span>
        </div>
        <br />
        <br />
      </>
    );
  }
);
ProfileComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  hideProfileLink: PropTypes.func.isRequired,
  callbackUserLogout: PropTypes.func.isRequired,
};
ProfileComponent.displayName = 'ProfileComponent';
export default ProfileComponent;
