import axios from 'axios';

const ProfileService = {
  getUser(id) {
    const url = `${process.env.REACT_APP_HOST_URL}user/${id}`;
    return axios.get(url);
  },

  logoutUser(id) {
    const url = `${process.env.REACT_APP_HOST_URL}auth/LogoutUser?ID=${id}`;
    return axios.post(url);
  },
};
export default ProfileService;
