import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Styles from './banner.module.css';
import searchIcon from '../../../assets/icons/Header-Search.png';
import closeIcon from '../../../assets/icons/Close.png';

function BannerComponent({
  componentName,
  addButtonText,
  isAddButtonHidden,
  callbackAddButtonFunction,
  handleSearch,
  isSearchHidden,
}) {
  const [searchKey, setSearchKey] = useState('');
  const [clearSearchIsActive, setClearSearchIsActive] = useState();
  const [saveSearchKey, setSaveSearchKey] = useState('');
  const searchRef = useRef(null);

  useEffect(() => {
    const backdropClicked = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setSearchKey(saveSearchKey);
        if (saveSearchKey) {
          setClearSearchIsActive(true);
        } else {
          setClearSearchIsActive(false);
        }
      }
    };

    document.addEventListener('mousedown', backdropClicked);
    return () => document.removeEventListener('mousedown', backdropClicked);
  }, [searchRef, saveSearchKey]);

  const onChangeSearchKey = (e) => {
    setSearchKey(e.target.value);
    setClearSearchIsActive(false);
  };

  const searchList = () => {
    setSaveSearchKey(searchKey);
    handleSearch(searchKey);
    setClearSearchIsActive(true);
  };

  const triggerSearchList = (e) => {
    if (e.key === 'Enter') searchList();
  };

  const clearSearchList = () => {
    setSaveSearchKey('');
    setSearchKey('');
    handleSearch(undefined);
    setClearSearchIsActive(false);
  };

  return (
    <div className={`${Styles.header} position-relative`}>
      <b className={Styles.headerText}>{componentName}</b>
      <div className="d-flex position-absolute end-0">
        {!isSearchHidden && (
          <div
            ref={searchRef}
            className="input-group"
            style={{ marginRight: isAddButtonHidden ? '0' : '20px' }}
          >
            <input
              type="text"
              style={{ textIndent: '5px' }}
              className={Styles.headerSearch}
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchKey}
              onChange={onChangeSearchKey}
              onKeyDown={triggerSearchList}
              // disabled={clearSearchIsActive}
            />
            <div
              className={`${Styles.searchIcon} input-group-append`}
              // style={{ backgroundColor: clearSearchIsActive && '#e9ecef' }}
            >
              {clearSearchIsActive ? (
                <img
                  className={Styles.searchInputCloseIcon}
                  style={{
                    cursor: 'pointer',
                    paddingLeft: '7px',
                    paddingRight: '15px',
                  }}
                  src={closeIcon}
                  alt="CloseIcon"
                  onClick={clearSearchList}
                  onKeyDown={clearSearchList}
                  role="presentation"
                  title="Clear"
                />
              ) : (
                <b
                  style={{
                    width: '35px',
                    paddingLeft: '7px',
                    paddingRight: '15px',
                  }}
                />
              )}
              {/* : ( */}
              <img
                className={Styles.searchInputSearchIcon}
                style={{
                  cursor: 'pointer',
                  pointerEvents: searchKey.length === 0 ? 'none' : null,
                }}
                src={searchIcon}
                alt="SearchIcon"
                onClick={searchList}
                onKeyDown={searchList}
                role="presentation"
                title="Search"
              />
            </div>
          </div>
        )}
        {!isAddButtonHidden && (
          <button
            className={`${Styles.addButton}`}
            onClick={callbackAddButtonFunction}
            type="button"
          >
            {addButtonText}
          </button>
        )}
      </div>
    </div>
  );
}
BannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  addButtonText: PropTypes.string,
  isAddButtonHidden: PropTypes.bool,
  callbackAddButtonFunction: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isSearchHidden: PropTypes.bool.isRequired,
};
BannerComponent.defaultProps = {
  addButtonText: '',
  isAddButtonHidden: true,
};
export default BannerComponent;
