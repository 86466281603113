import React, { useEffect, useState } from 'react';

import styles from './EmailTrigger.module.css';
import { success } from '../../shared/elements/notification/NotifyComponent';

import BannerComponent from '../_reusable/banner/BannerComponent';
import AuthenticationComponent from './AuthenticationComponent';
import GeneratingSchedulesComponent from './GeneratingSchedulesComponent';
import SubmittingSchedulesComponent from './SubmittingSchedulesComponent';
import GeneratingSchedulesSendTestModalComponent from './modals/GeneratingSchedulesSendTestModalComponent';
import EmailTriggerService from './EmailTriggerService';
import SubmittingSchedulesSendTestModalComponent from './modals/SubmittingSchedulesSendATestMailModalComponent';

const EmailTriggerComponent = () => {
    const [selectedTab, setSelectedTab] = useState('Generating schedules');
    const [generatingSchedulesParameters, setGeneratingSchedulesParameters] = useState({ generating: true });
    const [generatingSchedulesErrorFields, setGeneratingSchedulesErrorFields] = useState({});
    const [showGeneratingScheduleModal , setShowGeneratingScheduleModal] = useState(false);
    const [submittingSchedulesParameters, setSubmittingSchedulesParameters] = useState({ generating: false });
    const [submittingSchedulesErrorFields, setSubmittingSchedulesErrorFields] = useState({});
    const [showSubmittingScheduleModal , setShowSubmittingScheduleModal] = useState(false);
    const [isUpdatingGenerating, setIsUpdatingGenerating] = useState(false);
    const [isUpdatingSubmitting, setIsUpdatingSubmitting] = useState(false);

    const handleSetGeneratingSchedulesParameters = (e) => {
        setGeneratingSchedulesParameters({ ...generatingSchedulesParameters, [e.target.name]: e.target.value });
    };

    const handleGeneratingSchedulesFormValidation = () => {
        const recipients = generatingSchedulesParameters.recipients ? generatingSchedulesParameters.recipients.split(';') : [];
        const emailRegex = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/;
        const isRecipientsValid = recipients.every((o) => emailRegex.test(o));

        if (
            !generatingSchedulesParameters.subject
            || !generatingSchedulesParameters.recipients
            || (!isRecipientsValid
            && generatingSchedulesParameters.recipients)
        ) {
            setGeneratingSchedulesErrorFields({
                subjectState: !generatingSchedulesParameters.subject,
                subjectErrorMessage: 'Subject is required',
                recipientsState: !generatingSchedulesParameters.recipients
                    || (!isRecipientsValid && generatingSchedulesParameters.recipients),
                recipientsErrorMessage: !isRecipientsValid && generatingSchedulesParameters.recipients
                    ? 'Check the recipient(s) if it is a valid email format'
                    : 'Recipient(s) is required',
            });
            return false;
        }

        setGeneratingSchedulesErrorFields({});
        return true;
    };

    const handleOnSendaTestEmailGeneratingSchedules = () => {
        if (handleGeneratingSchedulesFormValidation()) {
            setShowGeneratingScheduleModal(true);
        }
    };

    const handleOnUpdateGeneratingSchedules = () => {
        if (handleGeneratingSchedulesFormValidation()) {
            setIsUpdatingGenerating(true);
            EmailTriggerService.updateEmailTemplate(generatingSchedulesParameters)
            .then(() => success('Email trigger details for generating schedules successfully updated'))
            .finally(() =>setIsUpdatingGenerating(false));
        }
    };

    const handleSetSubmittingSchedulesParameters = (e) => {
        setSubmittingSchedulesParameters({ ...submittingSchedulesParameters, [e.target.name]: e.target.value });
    };

    const handleSubmittingSchedulesFormValidation = () => {
        if (
            !submittingSchedulesParameters.subject
            || !submittingSchedulesParameters.recipients
        ) {
            setSubmittingSchedulesErrorFields({
                subjectState: !submittingSchedulesParameters.subject,
                subjectErrorMessage: 'Subject is required',
                recipientsState: !submittingSchedulesParameters.recipients,
                recipientsErrorMessage: 'Recipient(s) is required',
            });
            return false;
        }

        setSubmittingSchedulesErrorFields({});
        return true;
    };

    const handleOnSendaTestEmailSubmittingSchedules = () => {
        if (handleSubmittingSchedulesFormValidation()) {
            setShowSubmittingScheduleModal(true);
        }
    };

    const handleOnUpdateSubmittingSchedules = () => {
        if (handleSubmittingSchedulesFormValidation()) {
            setIsUpdatingSubmitting(true);
            EmailTriggerService.updateEmailTemplate(submittingSchedulesParameters)
            .then(() => success('Email trigger details for submitting schedules successfully updated'))
            .finally(() =>setIsUpdatingSubmitting(false));
        }
    };

    const handleGetEmailGeneratingTemplate = () => {
        EmailTriggerService.getEmailTemplate(true)
        .then((response) => {
            setGeneratingSchedulesParameters({
                ...response.data,
                generating: true,
            });
            
        });
    };
    
    const handleGetEmailSubmittingTemplate = () => {
        EmailTriggerService.getEmailTemplate(false)
        .then((response) => {
            setSubmittingSchedulesParameters({
                ...response.data,
                generating: false,
            }); 
        });
    };

    useEffect(() => {
        handleGetEmailGeneratingTemplate();
        handleGetEmailSubmittingTemplate();
    }, []);

    return (
        <div>
            <BannerComponent
                componentName="Email Triggers"
                isAddButtonHidden
                isSearchHidden
            />

            <hr style={{ borderTop: '1px  solid #b1b1b1', marginBottom: '8px' }} />

            <AuthenticationComponent />

            <div className={styles.tabHeaders}>
                <div
                    className={`${styles.tabHeaderItem} ${selectedTab === 'Generating schedules' && styles.tabHeaderItemActive}`}
                    onClick={() => setSelectedTab('Generating schedules')}
                >
                    Generating schedules
                </div>
                <div
                    className={`${styles.tabHeaderItem} ${selectedTab === 'Submitting schedules' && styles.tabHeaderItemActive}`}
                    onClick={() => setSelectedTab('Submitting schedules')}
                >
                    Submitting schedules
                </div>
            </div>
            <div className={styles.tabContents}>
                {selectedTab === 'Generating schedules' ? (
                    <GeneratingSchedulesComponent
                        parameters={generatingSchedulesParameters}
                        errorFields={generatingSchedulesErrorFields}
                        onSendaTestEmail={handleOnSendaTestEmailGeneratingSchedules}
                        onUpdate={handleOnUpdateGeneratingSchedules}
                        onChange={handleSetGeneratingSchedulesParameters}
                        isUpdating={isUpdatingGenerating}
                    />
                ) : (
                    <SubmittingSchedulesComponent
                        parameters={submittingSchedulesParameters}
                        errorFields={submittingSchedulesErrorFields}
                        onSendaTestEmail={handleOnSendaTestEmailSubmittingSchedules}
                        onUpdate={handleOnUpdateSubmittingSchedules}
                        onChange={handleSetSubmittingSchedulesParameters}
                        isUpdating={isUpdatingSubmitting}
                    />
                )}
            </div>

            <GeneratingSchedulesSendTestModalComponent
                predefinedRecipient={generatingSchedulesParameters.recipients}
                show={showGeneratingScheduleModal}
                onHide={() => setShowGeneratingScheduleModal(false)}
            />

            <SubmittingSchedulesSendTestModalComponent
                predefinedRecipient={submittingSchedulesParameters.recipients}
                show={showSubmittingScheduleModal}
                onHide={() => setShowSubmittingScheduleModal(false)}
            />
        </div>
    )
}

export default EmailTriggerComponent;