import axios from 'axios';
import React, { useCallback, useEffect, useState, Suspense } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import './AppFont.css';
import AuthService from './core/auth.service';
import ViewsComponent from './views/views';
import EventType from './enums/EventTypeEnum';
import ViewsService from './views/views.service';
import packageJson from '../package.json';
global.appVersion = packageJson.version;

const LoginComponent = React.lazy(() =>
  import('./components/pages/login/LoginComponent')
);
const ForgotPasswordComponent = React.lazy(() =>
  import('./components/forgot-password/ForgotPasswordComponent')
);
const PasswordResetComponent = React.lazy(() =>
  import('./components/password-reset/PasswordResetComponent')
);
const InternalServerComponent = React.lazy(() =>
  import('./components/_error-pages/internal-server/InternalServerComponent')
);
const AccessDeniedComponent = React.lazy(() =>
  import('./components/_error-pages/access-denied/AccessDeniedComponent')
);
const NotFoundComponent = React.lazy(() =>
  import('./components/_error-pages/not-found/NotFoundComponent')
);
const MaintenanceComponent = React.lazy(() =>
  import('./components/maintenance/MaintenanceComponent')
);

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthService.isAuthenticated
  );
  const userIdentity = AuthService.getUserIdentity();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [toNavigate, setToNavigate] = useState('');

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      const credentials = JSON.parse(
        window.sessionStorage.getItem('userIdentity')
      );

      if (event.key === EventType.Request_Credentials && credentials) {
        window.localStorage.setItem(
          EventType.Share_Credentials,
          JSON.stringify(credentials)
        );
        window.localStorage.removeItem(EventType.Share_Credentials);
      }
      if (event.key === EventType.Share_Credentials && !credentials) {
        window.sessionStorage.setItem('userIdentity', event.newValue);
        setIsAuthenticated(true);
      }
      if (event.key === EventType.Flush_Credentials && credentials) {
        window.sessionStorage.removeItem('userIdentity');
        setIsAuthenticated(false);
      }
    });

    window.localStorage.setItem(
      EventType.Request_Credentials,
      Date.now().toString()
    );
    window.localStorage.removeItem(EventType.Request_Credentials);

    setIsAuthenticated(AuthService.isAuthenticated());
  }, []);

  const navigateToDefaultLanding = () => {
    const landingPage =
      userIdentity?.roleId === 1
        ? `${process.env.REACT_APP_SUBFOLDER}/organisation`
        : `${process.env.REACT_APP_SUBFOLDER}/channel-profiles`;
    setToNavigate(landingPage);
  };

  const handleGetUserLastAccessPage = async (userId) => {
    const response = await ViewsService.getUserLastAccess(userId);
    if (response?.data) {
      const lastAccessPage =
        response.data == '/scheduler'
          ? process.env.REACT_APP_SUBFOLDER + '/schedule'
          : process.env.REACT_APP_SUBFOLDER + response.data;
      setToNavigate(lastAccessPage);
    } else {
      navigateToDefaultLanding();
    }
  };

  const handleOnLogin = useCallback(async (userId) => {
    setIsAuthenticated(true);
    await handleGetUserLastAccessPage(userId);
  }, []);

  const callbackUserLogout = () => {
    setIsAuthenticated(false);
  };

  const onAction = () => {
    setIsTimedOut(false);
  };

  const onActive = () => {
    setIsTimedOut(false);
  };

  const onIdle = () => {
    AuthService.logout();
    callbackUserLogout();
    navigate(`${process.env.REACT_APP_SUBFOLDER}/login`);
  };
  //disable idle timer
  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1800_000,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  axios.interceptors.request.use(
    (config) => {
      const token = AuthService.getToken();
      if (token) {
        // eslint-disable-next-line
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.clear();
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;
        if (response.data) {
          const { data } = error.response;
          if (data.statusCode === 401) {
            return Promise.reject(error);
          } else if (error.response.status === 403) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/access-denied`;
          } else if (data.status === 500 || data.statusCode === 500) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/internal-server/${data.message}`;
          } else if (data.statusCode === 404) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/not-found`;
          } else if (data.status === 408 || data.statusCode === 408) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/request-timed-out`;
          } else return Promise.reject(error);
        }
      }
      console.clear();
      return false;
    }
  );

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const callBackGetVersion = useCallback(() => {
    try {
      axios.get(`/meta.json?timestamp=${new Date().getTime()}`).then((meta) => {
        const latestVersion = meta.data.version;
        const currentVersion = global.appVersion;
        if (latestVersion !== currentVersion) refreshCacheAndReload();
      });
    } catch (error) {}
  }, []);

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <ViewsComponent
            toNavigate={toNavigate}
            callbackUserLogout={callbackUserLogout}
            callBackGetVersion={callBackGetVersion}
          />
        </>
      ) : (
        <Routes>
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/login`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <LoginComponent onLogin={handleOnLogin} />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/internal-server`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <InternalServerComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/access-denied`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <AccessDeniedComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/not-found`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <NotFoundComponent />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Navigate to={`${process.env.REACT_APP_SUBFOLDER}/login`} />
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/forgot-password`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <ForgotPasswordComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/passwordreset/:urlParamId`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <PasswordResetComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/maintenance`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <MaintenanceComponent />
              </Suspense>
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
