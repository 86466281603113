import styles from './styles.module.css';

function createNotificationContainer() {
  const portalId = 'notifyContainer';
  let element = document.getElementById(portalId);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', portalId);
  element.className = styles.container;
  document.body.appendChild(element);
  return element;
}

export default createNotificationContainer;
