import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import createNotificationContainer from './NotificationContainer';

import styles from './styles.module.css';

const container = createNotificationContainer();
const timeToDelete = 300;
const timeToClose = 5000;
function NotificationComponent({
  color,
  onDelete,
  children,
  autoClose = false,
}) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return null;
  }, [isClosing, onDelete]);

  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return null;
  }, [autoClose]);

  return createPortal(
    <div
      className={`alert alert-dismissible ${styles.notification} ${color} ${
        isClosing ? styles.slideOut : styles.slideIn
      }`}
      role="alert"
    >
      {children}
      <button
        type="button"
        className={`${styles.closeButton}`}
        onClick={() => setIsClosing(true)}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>,
    container,
  );
}
export const Color = {
  info: 'alert-info',
  success: 'alert-success',
  warning: 'alert-warning',
  error: 'alert-danger',
};

NotificationComponent.propTypes = {
  color: Color,
  notificationType: PropTypes.oneOf(Object.keys(Color)),
  children: PropTypes.element.isRequired,
};
NotificationComponent.defaultProps = {
  color: Color.info,
  notificationType: null,
};

export default NotificationComponent;
